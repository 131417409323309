import { Body } from '@sumup/circuit-ui';
import type { FC } from 'react';

import * as TestIds from 'shared/constants/TestIds';
import type { ProductType } from 'shared/types/product';

import { DescriptionContainer } from '../../OrderOverview.styles';

export type Props = {
  amountFormatted: string;
  productTypes: ProductType[];
  text: string;
  usedForCart: boolean;
};

export const getFormattedProductTypeNames = (
  productTypeIdentifiers: ProductType[],
): string => {
  const uniqueSorted = [
    ...new Set(
      productTypeIdentifiers
        .sort((pidA, pidB) => pidA?.identifier.localeCompare(pidB?.identifier))
        .map((pid) => pid?.name),
    ),
  ];

  const formatted = uniqueSorted.join(', ');

  return formatted;
};

export const SubtotalRow: FC<Props> = (props) => {
  const { amountFormatted, productTypes, text, usedForCart } = props;
  const formattedProductTypeNames = getFormattedProductTypeNames(productTypes);

  return (
    <DescriptionContainer
      data-testid={TestIds.OrderOverview.Subtotal}
      usedForCart={usedForCart}
    >
      <Body>{`${text} (${formattedProductTypeNames})`}</Body>
      <Body>{amountFormatted}</Body>
    </DescriptionContainer>
  );
};
